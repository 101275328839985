import { useMemo, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Paper,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";

import { AppAlert } from "../../common/appAlert/AppAlert";
import { DataTable } from "../../common/dataTable/DataTable";
import { MappingKeysFilters } from "../mappingKeysFilters/MappingKeysFilters";

import useMappingKeys from "../../../hooks/selfPromotion/useMappingKeys";

import { initLocalization } from "../../../utils/helpers";
import { mappingKeysStrings } from "./locale";
import {
  DEFAULT_TABLE_PAGINATION,
  DEFAULT_MAPPINGS_VIEW_FILTERS,
  DEFAULT_MAPPINGS_VIEW_SORTING,
} from "../../../constants";

export const MappingKeys = () => {
  initLocalization(mappingKeysStrings);

  const [error, setError] = useState("");
  const [filtersVersion, setFiltersVersion] = useState([]);
  const [filters, setFilters] = useState(DEFAULT_MAPPINGS_VIEW_FILTERS);
  const [pagination, setPagination] = useState(DEFAULT_TABLE_PAGINATION);
  const [sorting, setSorting] = useState(DEFAULT_MAPPINGS_VIEW_SORTING);

  const { isLoading, mappingKeys, getMappingKeys } = useMappingKeys(true);

  const mappingKeysData = useMemo(() => mappingKeys?.data, [mappingKeys]);

  const columns = [
    {
      field: "mediaType",
      headerName: mappingKeysStrings.mediaType,
    },
    {
      field: "category",
      headerName: mappingKeysStrings.category,
    },
    {
      field: "media",
      headerName: mappingKeysStrings.media,
    },
    {
      field: "advertiser",
      headerName: mappingKeysStrings.advertiser,
    },
    {
      field: "product",
      headerName: mappingKeysStrings.product,
    },
  ];

  const handleCloseError = () => setError(false);

  const handleResetFilters = () => {
    setPagination({ ...pagination, pageNumber: 0 });
    setFilters(DEFAULT_MAPPINGS_VIEW_FILTERS);
    setFiltersVersion((prev) => prev + 1);
    getMappingKeys({
      ...pagination,
      pageNumber: 1,
      DEFAULT_MAPPINGS_VIEW_FILTERS,
      ...sorting,
    });
  };

  const handleSearch = async () => {
    await getMappingKeys({
      ...pagination,
      pageNumber: 1,
      ...filters,
      ...sorting,
    });
    setPagination({ ...pagination, pageNumber: 0 });
  };

  const handleSelectRow = (e, index) => {};

  const handleSort = async (columnId, direction) => {
    if (!mappingKeysData.length) {
      return;
    }

    const sortColumn = columnId.charAt(0).toUpperCase() + columnId.slice(1);

    await getMappingKeys({
      ...pagination,
      pageNumber: 1,
      ...filters,
      ...sorting,
      sortOption: { sortColumn, direction },
    });
    setPagination({ ...pagination, pageNumber: 0 });
    setSorting({ ...sorting, sortOption: { sortColumn, direction } });
  };

  const handleChangePage = async (event, newPage) => {
    await getMappingKeys({
      ...pagination,
      pageNumber: newPage + 1,
      ...filters,
      ...sorting,
    });
    setPagination({ ...pagination, pageNumber: newPage });
  };

  const handleChangeRowsPerPage = async (event) => {
    const value = parseInt(event.target.value, 10);
    await getMappingKeys({
      ...pagination,
      pageNumber: 1,
      pageSize: value,
      ...filters,
      ...sorting,
    });
    setPagination({ ...pagination, pageNumber: 0, pageSize: value });
  };

  return (
    <Paper sx={{ padding: "0.5rem", height: "100%", position: "relative" }}>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {mappingKeysStrings?.errors?.[error]}
      </AppAlert>

      <Grid container direction="column" height="100%">
        <Grid xs={12} container justifyContent="space-between" minHeight="32px">
          <Typography>{mappingKeysStrings.title}</Typography>
          <Grid container>
            <Button
              sx={{ ml: 2 }}
              onClick={handleResetFilters}
              startIcon={<Refresh sx={{ transform: "scaleX(-1)" }} />}
              variant="text"
            >
              {mappingKeysStrings.resetFiltersButton}
            </Button>
          </Grid>
        </Grid>

        <Grid xs={12} paddingY={3}>
          <MappingKeysFilters
            key={filtersVersion}
            filters={filters}
            setFilters={setFilters}
            onSearch={handleSearch}
          />
        </Grid>

        <Grid xs={12} display="flex" flexDirection="column" flexGrow="1">
          <DataTable
            columns={columns}
            rows={mappingKeysData || []}
            page={pagination.pageNumber}
            rowsPerPage={pagination.pageSize}
            totalRecords={mappingKeys.totalRecords}
            sortBy={
              sorting.sortOption.sortColumn.charAt(0).toLowerCase() +
              sorting.sortOption.sortColumn.slice(1)
            }
            sortDirection={sorting?.sortOption?.direction}
            sortableColumns={columns.map((item) => item.field)}
            onSelectRow={handleSelectRow}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSort}
          />
        </Grid>
      </Grid>

      <Backdrop
        open={isLoading}
        sx={{
          position: "absolute",
          color: "#fff",
          borderRadius: 1,
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};
