import { useContext } from "react";
import { AppContext } from "../../root/Root";
import { DefaultLayout } from "../../../layouts/DefaultLayout";
import Grid from "@mui/material/Unstable_Grid2";
import { MappingKeys } from "../../../components/selfPromotion/mappingKeys/MappingKeys";
import "../../../style/common.scss";

export const MappingsView = () => {
  useContext(AppContext);

  return (
    <DefaultLayout>
      <Grid
        className="page-content"
        container
        rowSpacing={{ xs: 3, md: 0 }}
        columnSpacing={{ xs: 0, md: 2 }}
        padding={{ xs: 1, md: 2 }}
        disableEqualOverflow
      >
        <Grid xs={12}>
          <MappingKeys />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};
